import React from "react";
import Homepage from "../src/Pages/Home";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "./portfolio.css";

import "./App.css";

function App() {
  return (
    <div className="App">
      <Homepage />
    </div>
  );
}

export default App;
